<template>
  <div>
    <v-menu offset-y v-if="data">
      <template v-slot:activator="{ on }">
        <a text color="primary" v-on="on">
          <v-icon :small="small" color="primary">mdi-share-variant</v-icon>
        </a>
      </template>

      <!-- :description="data.description" -->
      <social-sharing :url="data.url" :title="data.title" inline-template>
        <v-list shaped>
          <!-- <v-list-item class="cursor-pointer">
            <network network="email">
              <v-icon>mdi-gmail</v-icon> Email
            </network>
          </v-list-item> -->
          <v-list-item>
            <network network="facebook">
              <v-icon>mdi-facebook-box</v-icon> Facebook
            </network>
          </v-list-item>
          <!-- <v-list-item>
            <network network="line"> <i class="fa fa-line"></i> Line </network>
          </v-list-item> -->
          <!-- <v-list-item>
            <network network="linkedin">
              <v-icon>mdi-linkedin-box</v-icon> LinkedIn
            </network>
          </v-list-item> -->
          <!-- <v-list-item>
            <network network="pinterest">
              <i class="fa fa-pinterest"></i> Pinterest
            </network>
          </v-list-item> -->
          <!-- <v-list-item>
            <network network="skype">
              <i class="fa fa-skype"></i> Skype
            </network>
          </v-list-item> -->
          <!-- <v-list-item v-if="$vuetify.breakpoint.xsOnly">
            <network network="sms">
              <v-icon>mdi-message-text</v-icon> SMS
            </network>
          </v-list-item> -->
          <v-list-item>
            <network network="telegram">
              <v-icon>mdi-telegram</v-icon> Telegram
            </network>
          </v-list-item>
          <v-list-item>
            <network network="twitter">
              <v-icon>mdi-twitter</v-icon> Twitter
            </network>
          </v-list-item>
          <v-list-item>
            <network network="whatsapp">
              <v-icon>mdi-whatsapp</v-icon> Whatsapp
            </network>
          </v-list-item>
        </v-list>
      </social-sharing>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    small: Boolean
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
