import request from "@/utils/request";

export function getInformation(data) {
  return request({
    method: "post",
    url: "announcement/list-all",
    data: data
  });
}

export function getInformationEmployee(data) {
  return request({
    method: "post",
    url: "announcement/list",
    data: data
  });
}

export function addInformation(data) {
  return request({
    method: "post",
    url: "announcement/add",
    data: data
  });
}

export function updateInformation(data) {
  return request({
    method: "post",
    url: "announcement/update",
    data: data
  });
}

export function getTypeFor() {
  return request({
    method: "get",
    url: "announcement/master"
  });
}

export function deleteInformation(data) {
  return request({
    method: "post",
    url: "announcement/delete",
    data: data
  });
}

export function getActiveClass() {
  return request({
    method: "get",
    url: "class/class-active"
  });
}

export function setLikes(data) {
  return request({
    method: "post",
    url: "announcement/like",
    data: data
  });
}
