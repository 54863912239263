<template>
  <v-sheet>
    <v-card class="elevation-0 pa-3">
      <v-row no-gutters align="center">
        <v-btn class="gradient-primary" dark depressed @click="modalForm">
          {{
            type == "announcement"
              ? $t("announcement.add_announcement")
              : $t("news.add_news")
          }}
          <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
        </v-btn>

        <span class="ml-2"
          >{{ total }}
          {{
            type == "announcement"
              ? $t("routes.announcement")
              : $t("routes.news")
          }}</span
        >
        <v-spacer></v-spacer>

        <v-text-field
          v-model="queryData.search"
          :placeholder="
            type == 'announcement'
              ? $t('announcement.search_announcement')
              : $t('news.search_news')
          "
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          hide-details
          style="max-width: 250px"
        />
      </v-row>
    </v-card>

    <Form
      :dialog="openForm"
      :tempEdit="tempEdit"
      :typeForm="tempType"
      :typeForList="typeForList"
      :type="type"
      :closeModal="
        param => {
          if (param == 'save') this.getData();
          this.tempType = '';
          openForm = false;
          tempEdit = {};
        }
      "
    />

    <v-row no-gutters class="px-3">
      <v-spacer></v-spacer>
      <v-btn v-if="selected.length > 0" color="error" @click="modalDelete()">
        {{ $t("app.delete_bulk") }}
      </v-btn>
    </v-row>

    <v-card flat class="elevation-0 px-3">
      <v-row no-gutters justify="end" class="mb-3">
        <v-checkbox
          v-model="queryData.my_information"
          hide-details
          :label="
            type == 'announcement'
              ? $t('announcement.my_announcement')
              : $t('news.my_news')
          "
          @change="setMyQuestion()"
        ></v-checkbox>
      </v-row>
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        :items="dataTable"
        :page.sync="queryData.page"
        :items-per-page="queryData.limit"
        :loading="loading"
        fixed-header
        show-select
        hide-default-footer
        disable-pagination
        class="elevation-0"
      >
        <template v-slot:item.created_date="{ item }">
          {{ item.created_date | dateFormat }}
        </template>
        <template v-slot:item.type_for="{ item }">
          {{ setLabel(item.type_for) }}
        </template>
        <template v-slot:item.description="{ item }">
          <span v-html="truncateHtml(item.description)"></span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="me == item.creator"
            icon
            small
            dark
            class="primary"
            :disabled="loading"
            @click="getListData(item, 'detail')"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            small
            dark
            class="primary"
            :disabled="loading"
            @click="getListData(item, 'detail')"
          >
            <v-icon small>mdi-eye</v-icon>
          </v-btn>

          <v-btn
            v-if="me == item.creator"
            icon
            small
            dark
            class="primary mx-2"
            :disabled="loading"
            @click="getListData(item, 'edit')"
          >
            <v-icon small>mdi-pencil-box-outline</v-icon>
          </v-btn>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small dark class="grey mx-2">
                <v-icon small>mdi-pencil-box-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("app.edit_for_creator") }}</span>
          </v-tooltip>

          <v-btn
            v-if="me == item.creator"
            icon
            small
            :dark="selected.length > 1 ? false : true"
            :class="selected.length > 1 ? '' : 'primary'"
            :disabled="loading || selected.length > 1"
            @click="modalDelete(item.id)"
          >
            <v-icon small>mdi-delete-forever</v-icon>
          </v-btn>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small dark class="grey">
                <v-icon small>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("app.delete_for_creator") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <Pagination
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />

      <ModalConfirm
        :isOpen="confirmDelete"
        :close="
          param => {
            confirmDelete = false;
            if (param == 'save') this.getData();
          }
        "
        :save="deleteHandler"
        :loading="loading"
      />
    </v-card>
  </v-sheet>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");
import truncate from "truncate-html";

import Form from "./form";
import {
  getInformationEmployee,
  deleteInformation,
  getTypeFor
} from "@/api/admin/information";

export default {
  props: {
    type: String,
    tab: Number
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    ModalConfirm: () => import("@/components/ModalConfirm"),
    Form
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      me: this.$store.getters.g_user.fullname,
      selected: [],
      openForm: false,
      tempEdit: {},
      loading: false,
      confirmDelete: false,
      tempType: "",
      deleteId: null,
      tableHeaders: [
        {
          text: i18n.t("information.table.date"),
          align: "left",
          value: "created_date"
        },
        {
          text: i18n.t("information.table.title"),
          value: "title"
        },
        {
          text: i18n.t("information.table.shown_for"),
          value: "type_for"
        },
        {
          text: i18n.t("information.table.content"),
          sortable: false,
          value: "description"
        },
        // {
        //   text: i18n.t("information.table.total"),
        //   sortable: false,
        //   value: "total"
        // },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      queryData: {
        page: 1,
        limit: 10,
        sort: "created_at",
        order: "DESC",
        search: "",
        type: this.type,
        my_information:
          this.$store.getters.g_role_type === "ADMIN" ? false : true
      },
      total: 0,
      length: 0,
      dataTable: [],
      typeForList: []
    };
  },
  watch: {
    queryData: {
      handler() {
        this.getData();
      },
      deep: true
    },
    tab(n) {
      if (n == 1) this.getData();
    }
  },
  mounted() {
    this.getData();
    getTypeFor().then(res => {
      if (res.data.code) {
        this.typeForList = res.data.data;
      }
    });
  },
  methods: {
    setLabel(item) {
      let label = "";
      label = this.typeForList.find(r => r.type == item);
      if (label) return label.name;
      else return item.type_for;
    },
    truncateHtml: html => truncate(html, 3, { byWords: true, stripTags: true }),
    modalForm() {
      this.openForm = true;
      this.tempType = "add";
    },
    setFilter(param) {
      console.log(param);
    },
    getData() {
      this.loading = true;
      getInformationEmployee(this.queryData)
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data.data;
            this.total = res.data.data.total;
            this.length = res.data.data.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    getListData(data, type) {
      this.$Progress.start();
      this.tempEdit = {};
      getInformationEmployee({ id: data.id })
        .then(res => {
          if (res.data.code) {
            if (res.data.data.length > 0) {
              this.tempType = type;
              this.$nextTick(() => {
                this.tempEdit = res.data.data[0];
                this.openForm = true;
              });
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.$t("app.data_not_found"),
                color: "error"
              });
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.$Progress.finish();
        })
        .catch(() => {
          this.$Progress.finish();
        });
    },
    modalDelete(id) {
      if (id) {
        this.deleteId = [id];
      } else {
        this.deleteId = this.selected.map(r => r.id);
      }
      this.confirmDelete = true;
    },
    deleteHandler() {
      this.loading = true;
      deleteInformation({ id: this.deleteId })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("app.success_delete"),
              color: "success"
            });
            this.confirmDelete = false;
            this.getData();
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
