<template>
  <v-sheet>
    <v-card class="elevation-0 pa-3">
      <v-row no-gutters align="center">
        <v-text-field
          v-model="queryData.search"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          class="mr-2"
          hide-details
          :placeholder="`${$t('app.search')} ${$t('app.' + type)}`"
          style="max-width: 250px"
          @keyup="searchHandler"
        />
        <span>{{ total }} {{ $t("routes." + type) }}</span>
        <v-spacer></v-spacer>

        <v-menu v-model="menu" :close-on-content-click="false" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" outlined v-bind="attrs" v-on="on">
              Filter
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item
                :class="activeFilter == 'latest' && 'gradient-primary'"
                @click="setFilter('latest')"
              >
                <v-list-item-action
                  :class="activeFilter == 'latest' && 'white--text'"
                  >{{ $t("app.latest") }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item
                :class="activeFilter == 'oldest' && 'gradient-primary'"
                @click="setFilter('oldest')"
              >
                <v-list-item-action
                  :class="activeFilter == 'oldest' && 'white--text'"
                  >{{ $t("app.oldest") }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item
                :class="activeFilter == 'most_viewed' && 'gradient-primary'"
                @click="setFilter('most_viewed')"
              >
                <v-list-item-action
                  :class="activeFilter == 'most_viewed' && 'white--text'"
                  >{{ $t("app.most_viewed") }}</v-list-item-action
                >
              </v-list-item>
              <v-list-item
                :class="activeFilter == 'most_liked' && 'gradient-primary'"
                @click="setFilter('most_liked')"
              >
                <v-list-item-action
                  :class="activeFilter == 'most_liked' && 'white--text'"
                  >{{ $t("app.most_liked") }}</v-list-item-action
                >
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-row>
    </v-card>

    <v-divider></v-divider>

    <v-card flat class="elevation-0 pb-3">
      <v-row
        v-if="data.length == 0 && dataNotAvailable"
        justify="center"
        align="center"
      >
        <v-img
          v-if="type == 'announcement'"
          src="@/assets/svg/no_announcement.svg"
          max-width="400px"
        ></v-img>
        <v-img
          v-if="type == 'news'"
          src="@/assets/svg/no_news.svg"
          max-width="400px"
        ></v-img>
      </v-row>
      <v-row v-else justify="start" align="center">
        <v-col
          cols="12"
          sm="6"
          lg="3"
          v-for="(a, i) in data"
          :key="i"
          class="mt-4"
        >
          <v-card class="text-left" outlined>
            <v-img
              v-if="a.file_type == 'youtube' || a.file_type == 'image'"
              :src="a.thumbnail"
              height="180"
            ></v-img>
            <div v-else class="pa-4">
              <v-img :src="a.thumbnail" height="150" contain></v-img>
            </div>

            <v-card-title class="subtitle-1 mt-n1">
              <a
                color="primary"
                class="card-title-wrap text-truncate"
                text
                @click="openDetail(a)"
                >{{ a.title }}
              </a>
            </v-card-title>
            <v-card-text>
              <div
                v-if="a.description != isFalse"
                class="caption card-caption-wrap"
                v-html="a.description"
              ></div>
              <div
                v-if="a.description == isFalse"
                class="caption card-caption-wrap"
              >
                {{ $t("homepage.no_description") }}
              </div>
              <span class="font-weight-light" style="font-size: 11px">
                {{ $t("app.posted_on") }} {{ a.created_date | dateFormat }}
              </span>
            </v-card-text>
            <v-card-actions class="mx-2" style="font-size: 11px">
              <a class="mr-2">
                <v-icon
                  small
                  :color="a.status_likes ? 'primary' : ''"
                  @click="likeHandler(a.id)"
                  >mdi-thumb-up-outline</v-icon
                >
                <span class="ml-1 grey--text">{{ a.likes }}</span>
              </a>
              <a class="mr-2">
                <v-icon small>mdi-eye-outline</v-icon>
                <span class="ml-1 grey--text">{{ a.view }}</span>
              </a>
              <v-spacer></v-spacer>
              <SocialSharing small :data="setSharing(a)" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-if="data.length != total"
        justify="center"
        class="mt-4"
        color="primary"
      >
        <v-btn text @click="viewMore">View More</v-btn>
      </v-row>

      <v-dialog v-model="modalDetail" persistent width="900" scrollable>
        <v-card v-if="detail" scrollable>
          <v-card-title class="headline">
            {{ detail.title }}
            <v-spacer></v-spacer>
            <v-icon
              @click="
                () => {
                  modalDetail = false;
                  getListData();
                  detail = null;
                }
              "
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-3">
            <div v-html="detail.description"></div>
            <div v-if="detail.description == isFalse">
              {{ $t("homepage.no_description") }}
            </div>
            <v-divider class="py-2"></v-divider>
            <v-row v-if="detail.attachment.length > 0">
              <v-col v-if="detail.attachment[0].type == 'image'">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="4"
                    v-for="d in detail.attachment"
                    :key="d.id"
                  >
                    <v-img :src="d.path" @click="openImage(d.path)"></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-else
                cols="12"
                v-for="d in detail.attachment"
                :key="d.id"
              >
                <v-row justify="center">
                  <iframe
                    v-if="d.type == 'youtube'"
                    :src="getUrl()"
                    frameborder="0"
                    style="height: 450px"
                    width="100%"
                    allowfullscreen
                    no-gutters
                  ></iframe>
                </v-row>

                <v-row v-if="d.type == 'pdf'" no-gutters>
                  <object
                    v-if="d.type == 'pdf'"
                    :data="getUrl()"
                    height="450px"
                    width="100%"
                    type="application/pdf"
                  >
                    <iframe
                      :src="
                        `https://docs.google.com/viewer?url=${getUrl()}&embedded=true`
                      "
                    ></iframe>
                  </object>
                </v-row>

                <v-row v-if="d.type == 'video'" no-gutters>
                  <video height="450" width="100%" controls>
                    <source :src="getUrl()" />
                  </video>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="py-2"></v-divider>

          <v-card-actions class="mx-2" style="font-size: 11px; display: block">
            <div class="grey--text caption">
              {{ $t("app.posted_on") }}
              {{ detail.created_date | dateFormat }}
            </div>
            <v-row no-gutters class="my-3">
              <a class="mr-2" @click="likeHandler(detail.id)">
                <v-icon small :color="detail.status_likes ? 'primary' : ''"
                  >mdi-thumb-up-outline</v-icon
                >
                <span class="ml-1 grey--text">{{ detail.likes }}</span>
              </a>
              <a class="mr-2">
                <v-icon small color="primary">mdi-eye-outline</v-icon>
                <span class="ml-1 grey--text">{{ detail.view }}</span>
              </a>
              <v-spacer></v-spacer>
              <SocialSharing :data="setSharing(detail)" small />
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-sheet>
</template>

<script>
import { getInformationEmployee, setLikes } from "@/api/admin/information";
import moment from "moment/moment";
moment.locale("id");

import SocialSharing from "@/components/SocialSharing";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    type: String,
    tab: Number
  },
  components: {
    SocialSharing
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      menu: null,
      thumbnail: {
        pdf: require("@/assets/svg/thumbnail_pdf.svg"),
        video: require("@/assets/svg/thumbnail_video.svg"),
        nofilenews: require("@/assets/svg/thumbnail_nofile_news.svg"),
        nofileannouncement: require("@/assets/svg/thumbnail_nofile_announcement.svg")
      },
      dataNotAvailable: false,
      dataNews: 0,
      searchValue: "",
      data: [],
      total: 0,
      modalDetail: false,
      detail: null,
      activeFilter: "",
      isFalse: "",
      queryData: {
        id: null,
        limit: 8,
        type: this.type,
        search: "",
        sort: "created_at",
        order: "DESC"
      }
    };
  },
  created() {
    this.getListData();
  },
  watch: {
    tab(n) {
      if (n == 0) this.getListData();
    }
  },
  methods: {
    setSharing(data) {
      const setUrl = `${window.location.origin}/information/${
        data.type
      }/detail?id=${data.id}&title=${data.title.split(" ").join("-")}`;
      return {
        url: setUrl,
        title: data.title,
        description: data.description
      };
    },
    openImage(path) {
      window.open(path);
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getListData();
      }, doneTypingInterval);
    },
    getUrl() {
      if (this.modalDetail && this.detail.attachment.length > 0) {
        const getType = this.detail.attachment[0].type;
        switch (getType) {
          case "youtube":
            return this.splitUrl(this.detail.attachment[0].path);
          default:
            return this.detail.attachment[0].path;
        }
      }
    },
    splitUrl(url, onlyCode) {
      if (url) {
        const splitUrl = url.split("watch?v=");
        if (
          splitUrl[0] == "https://www.youtube.com/" ||
          splitUrl[0] == "www.youtube.com/"
        ) {
          let idYoutube = url.split("v=")[1];
          var ampersAndPosition = idYoutube.indexOf("&");
          if (ampersAndPosition != -1) {
            idYoutube = idYoutube.substring(0, ampersAndPosition);
          }
          if (onlyCode) {
            return idYoutube;
          }
          return "https://www.youtube.com/embed/" + idYoutube;
        }
      }
    },
    likeHandler(id) {
      this.$Progress.start();
      setLikes({ id: id }).then(res => {
        if (res.data.code) {
          if (this.detail) {
            this.openDetail(this.detail);
          } else {
            this.getListData();
          }
        }
        this.$Progress.finish();
      });
    },
    openDetail(param) {
      this.$Progress.start();
      getInformationEmployee({ ...this.queryData, id: param.id }).then(res => {
        if (res.data.code) {
          const data = res.data.data;
          if (data.length > 0) {
            this.detail = data[0];
            this.modalDetail = true;
          }
        } else {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: "error"
          });
        }
        this.$Progress.finish();
      });
    },
    viewMore() {
      this.queryData.limit = this.queryData.limit + this.queryData.limit;
      this.getListData();
    },
    getListData() {
      this.dataNotAvailable = false;
      this.$Progress.start();
      getInformationEmployee(this.queryData)
        .then(res => {
          if (res.data.code) {
            const tempData = [];
            const data = res.data.data.data;

            if (data.length > 0) {
              data.map(r => {
                let thumbnail = "";
                if (r.file_type) {
                  switch (r.file_type) {
                    case "youtube": {
                      const code = this.splitUrl(r.thumbnail, "onlyCode");
                      if (code)
                        thumbnail = `https://img.youtube.com/vi/${code}/0.jpg`;
                      break;
                    }
                    case "pdf":
                      thumbnail = this.thumbnail.pdf;
                      break;
                    case "video":
                      thumbnail = this.thumbnail.video;
                      break;
                    default:
                      thumbnail = r.thumbnail;
                      break;
                  }
                } else {
                  if (this.type == "news") {
                    thumbnail = this.thumbnail.nofilenews;
                  } else {
                    thumbnail = this.thumbnail.nofileannouncement;
                  }
                }
                tempData.push({ ...r, thumbnail: thumbnail });
              });
            } else {
              this.dataNotAvailable = true;
            }

            this.data = tempData;
            this.total = res.data.data.total;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.$Progress.finish();
        })
        .catch(() => this.$Progress.finish());
    },
    setFilter(param) {
      this.activeFilter = param;
      this.queryData.order = "DESC";
      switch (param) {
        case "latest":
          this.queryData.sort = "created_at";
          break;
        case "oldest":
          this.queryData.sort = "created_at";
          this.queryData.order = "ASC";
          break;
        case "most_viewed":
          this.queryData.sort = "view";
          break;
        default:
          this.queryData.sort = "likes";
          break;
      }
      this.getListData();
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-wrapper {
  width: 250px;
  height: auto;
}
.card-title-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-caption-wrap {
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
