<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>
        {{ createTitle() }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModalHandler(typeForm)">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="valid">
          <v-row align="center">
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.title"
                :rules="[
                  v => !!v || $t('app.required'),
                  v =>
                    (v && v.length <= 128) ||
                    $t('announcement.max_length_title')
                ]"
                :label="$t('information.form.title')"
                :readonly="typeForm == 'detail'"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="form.type_for == 'kelas_tertentu' ? 6 : 12">
              <v-select
                v-model="form.type_for"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('information.form.shown_for')"
                :readonly="typeForm == 'detail'"
                :items="typeForList"
                item-text="name"
                item-value="type"
                dense
                outlined
                required
                @change="selectClass"
              />
            </v-col>
            <v-col v-if="form.type_for == 'kelas_tertentu'" cols="12" sm="6">
              <v-select
                v-model="form.class"
                :label="$t('information.form.class')"
                :items="classList"
                item-text="name"
                item-value="id"
                :loading="loadingClassList"
                :readonly="typeForm == 'detail'"
                multiple
                dense
                outlined
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-radio-group
                v-model="form.type_file"
                :readonly="typeForm == 'detail'"
                row
                hide-details
                class="mt-0"
                @change="changeType"
              >
                <v-radio label="Youtube" value="youtube"></v-radio>
                <v-radio label="Gambar" value="image"></v-radio>
                <v-radio label="PDF" value="pdf"></v-radio>
                <v-radio label="Video" value="video"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <span v-if="typeForm != 'detail'">
                <v-text-field
                  v-if="form.type_file == 'youtube'"
                  v-model="url"
                  label="Link Youtube"
                  prepend-inner-icon="mdi-link-variant"
                  outlined
                  clearable
                  dense
                  @click:clear="
                    () => {
                      this.form.url = [];
                      this.url = '';
                      this.form.type_file = '';
                    }
                  "
                ></v-text-field>
                <v-file-input
                  v-if="form.type_file == 'image'"
                  v-model="file"
                  :label="$t('app.upload') + ' ' + $t('app.image')"
                  :loading="loadingUpload"
                  prepend-inner-icon="mdi-image"
                  prepend-icon=""
                  outlined
                  dense
                  clearable
                  multiple
                  accept="image/*"
                  @change="uploadFiles"
                  @click:clear="deleteFileHandler(null)"
                ></v-file-input>
                <v-file-input
                  v-if="form.type_file == 'pdf'"
                  v-model="file"
                  :label="$t('app.upload') + ' PDF'"
                  :loading="loadingUpload"
                  prepend-inner-icon="mdi-file-pdf"
                  prepend-icon=""
                  clearable
                  outlined
                  dense
                  accept="application/pdf"
                  @change="uploadFiles"
                  @click:clear="deleteFileHandler(null)"
                ></v-file-input>
                <v-file-input
                  v-if="form.type_file == 'video'"
                  v-model="file"
                  :label="$t('app.upload') + ' Video'"
                  :loading="loadingUpload"
                  prepend-inner-icon="mdi-video"
                  clearable
                  prepend-icon=""
                  outlined
                  dense
                  accept="video/*"
                  @change="uploadFiles"
                  @click:clear="deleteFileHandler(null)"
                ></v-file-input>
              </span>

              <iframe
                v-if="getUrl() && form.type_file == 'youtube'"
                :src="getUrl()"
                frameborder="0"
                height="350px"
                width="100%"
                allowfullscreen
              ></iframe>

              <v-row v-if="form.type_file == 'image'">
                <v-col v-for="(u, i) in form.url" :key="i" cols="12" sm="6">
                  <v-img v-if="u.file" :src="u.file" style="position: relative">
                    <v-btn
                      v-if="typeForm != 'detail'"
                      x-small
                      fab
                      style="position: absolute; right: 10px; top: 10px;"
                      color="error"
                      @click="deleteFileHandler(u.file)"
                    >
                      <v-icon small>mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-img>
                </v-col>
              </v-row>

              <v-row v-if="form.type_file == 'pdf'" no-gutters>
                <object
                  v-for="(u, i) in form.url"
                  :key="i"
                  :data="u.file"
                  height="400px"
                  width="100%"
                  type="application/pdf"
                  style="position: relative"
                >
                  <iframe
                    :src="
                      `https://docs.google.com/viewer?url=${u.file}&embedded=true`
                    "
                  ></iframe>
                </object>
              </v-row>

              <v-row v-if="form.type_file == 'video'" no-gutters>
                <video
                  v-for="(u, i) in form.url"
                  :key="i"
                  height="auto"
                  width="100%"
                  controls
                >
                  <source :src="u.file" />
                </video>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="my-4"></v-divider>

          <v-row no-gutters>
            <v-col cols="12">
              <div v-if="typeForm == 'detail'">
                <div class="body-1 mb-2 font-weight-bold">
                  {{ $t("app.description") }}
                </div>
                <span v-html="form.description"></span>
              </div>
              <quill-editor
                v-else
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
              />
            </v-col>
          </v-row>

          <v-row v-if="typeForm != 'detail'" no-gutters>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid || loadingUpload"
              :loading="loadingSubmit"
              color="primary"
              depressed
              @click="submit"
              class="mt-3"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { uploadFile, deleteFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";
import {
  getActiveClass,
  addInformation,
  updateInformation
} from "@/api/admin/information";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

export default {
  props: {
    dialog: Boolean,
    closeModal: Function,
    tempEdit: Object,
    type: String,
    typeForm: String,
    typeForList: Array
  },
  components: {
    quillEditor
  },
  data() {
    return {
      valid: false,
      basicCompetenciesList: [],
      loadingCompetencies: false,
      file: null,
      loadingUpload: false,
      loadingSubmit: false,
      loadingClassList: false,
      tempDelete: [],
      url: "",
      form: {
        id: "",
        title: "",
        type: this.type,
        type_for: "",
        url: [],
        description: "",
        class: []
      },
      classList: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["formula"]
          ]
        }
      }
    };
  },
  watch: {
    async dialog(nv) {
      if (nv) {
        if (this.tempEdit.id) {
          let fileTemp = [];
          if (this.tempEdit.attachment.length > 0) {
            if (this.tempEdit.attachment[0].type == "youtube") {
              this.url = this.tempEdit.attachment[0].path;
            } else {
              this.tempEdit.attachment.map(r => {
                fileTemp.push({
                  type: r.type,
                  filename: r.name,
                  file: r.path
                });
              });
            }
          }

          this.form = {
            id: this.tempEdit.id,
            title: this.tempEdit.title,
            type_file:
              this.tempEdit.attachment.length > 0
                ? this.tempEdit.attachment[0].type
                : "",
            url: fileTemp,
            type_for: this.tempEdit.type_for,
            description: this.tempEdit.description
          };

          if (this.tempEdit.class.length > 0) this.selectClass();
        }
      }
    }
  },
  computed: {
    moduleFileName() {
      return this.type.toUpperCase();
    }
  },
  created() {
    window.katex = katex;
  },
  methods: {
    selectClass() {
      this.form.class = [];
      if (this.form.type_for == "kelas_tertentu") {
        this.loadingClassList = true;
        getActiveClass().then(res => {
          if (res.data.code) {
            this.classList = res.data.data;
            if (this.tempEdit.id) {
              this.form.class = this.tempEdit.class;
            }
          }
          this.loadingClassList = false;
        });
      }
    },
    createTitle() {
      switch (this.typeForm) {
        case "edit":
          if (this.type == "announcement")
            return this.$i18n.t("announcement.edit_announcement");
          else return this.$i18n.t("news.edit_news");
        case "add":
          if (this.type == "announcement")
            return this.$i18n.t("announcement.add_announcement");
          else return this.$i18n.t("news.add_news");
        default:
          if (this.type == "announcement")
            return this.$i18n.t("announcement.detail_announcement");
          else return this.$i18n.t("news.detail_news");
      }
    },
    async closeModalHandler(param) {
      if (param == "save") {
        this.file = null;
        this.url = "";
        this.closeModal("save");
      } else if (param == "edit" || param == "detail") {
        this.file = null;
        this.url = "";
        this.closeModal();
      } else {
        if (this.form.url.length > 0) {
          this.form.url.map(async r => {
            const path = await sliceAwsPath(r.file);
            await deleteFile(path, this.moduleFileName);
          });
        }
        this.closeModal();
      }

      this.form = {
        id: "",
        title: "",
        type: this.type,
        type_for: "",
        url: [],
        description: "",
        class: []
      };
      this.tempDelete = [];
      this.$refs.form.reset();
    },
    async changeType() {
      if (this.tempEdit.id) {
        this.deleteFileHandler(null);
      } else {
        if (this.form.type != "youtube") {
          this.form.url.map(async r => {
            const path = await sliceAwsPath(r.file);
            await deleteFile(path, this.moduleFileName);
          });
        }
        this.url = "";
        this.form.url = [];
        this.file = null;
      }
    },
    getUrl() {
      if (this.dialog) {
        if (this.form.type_file == "youtube") {
          if (this.splitUrl()) return this.splitUrl();
          else {
            if (this.url) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.invalid_youtube_url"),
                color: "error"
              });
              this.url = "";
            }
          }
        } else {
          if (this.tempEdit.id) {
            if (this.tempEdit.attachment) {
              return this.form.url.length > 0 ? this.form.url[0].file : "";
            } else {
              return "";
            }
          } else {
            return "";
          }
        }
      }
    },
    splitUrl() {
      if (this.url) {
        const splitUrl = this.url.split("watch?v=");
        if (
          splitUrl[0] == "https://www.youtube.com/" ||
          splitUrl[0] == "www.youtube.com/"
        ) {
          let idYoutube = this.url.split("v=")[1];
          var ampersAndPosition = idYoutube.indexOf("&");
          if (ampersAndPosition != -1) {
            idYoutube = idYoutube.substring(0, ampersAndPosition);
          }

          return "https://www.youtube.com/embed/" + idYoutube;
        }
      }
    },
    async uploadFiles() {
      if (this.file.length > 0 && this.form.type_file == "image") {
        await this.file.map(r => {
          this.loadingUpload = true;
          let bodyImage = new FormData();
          bodyImage.set("path", `announcement/${this.form.type_file}`);
          bodyImage.set("module", this.moduleFileName);
          bodyImage.append("file", r);
          uploadFile(bodyImage)
            .then(res => {
              if (res.data.code) {
                this.form.url.push({
                  type: this.form.type_file,
                  filename: r.name,
                  file: res.data.data.path
                });
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingUpload = false;
            })
            .catch(() => {
              this.loadingUpload = false;
            });
        });
      } else if (this.file && this.form.type_file) {
        this.loadingUpload = true;
        let bodyImage = new FormData();
        bodyImage.set("path", `${this.type}/${this.form.type_file}`);
        bodyImage.set("module", this.moduleFileName);
        bodyImage.append("file", this.file);
        uploadFile(bodyImage)
          .then(res => {
            if (res.data.code) {
              this.form.url.push({
                type: this.form.type_file,
                filename: "",
                file: res.data.data.path
              });
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingUpload = false;
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },
    async deleteFileHandler(file) {
      if (this.tempEdit.id) {
        if (file) {
          const getDeleteFilter = [];

          await this.form.url.map(r => {
            if (r.file == file) this.tempDelete.push(r);
          });

          await this.form.url.map(r => {
            if (r.file != file) getDeleteFilter.push(r);
          });
          this.form.url = getDeleteFilter;

          if (getDeleteFilter.length == 0) this.form.type_file = "";
        } else {
          this.tempDelete = JSON.parse(JSON.stringify(this.form.url));
        }
      } else {
        if (this.form.type_file != "youtube") {
          await Promise.all(
            this.form.url.map(async r => {
              const path = await sliceAwsPath(r.file);
              await deleteFile(path, this.moduleFileName);
            })
          );
          this.form.url = [];
          this.form.type_file = "";
        } else {
          this.form.url = [];
          this.url = "";
          this.form.type_file = "";
        }
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let url = [];

        if (this.tempDelete.length > 0) {
          await Promise.all(
            this.tempDelete.map(async r => {
              const path = await sliceAwsPath(r.file);
              await deleteFile(path, this.moduleFileName);
            })
          );
        }

        if (this.form.type_file == "youtube") {
          url = [
            {
              type: "youtube",
              filename: "",
              file: this.url
            }
          ];
        } else url = this.form.url;

        let data = {
          ...this.form,
          attachment: url
        };

        this.loadingSubmit = true;
        if (this.tempEdit.id) {
          data.id = this.tempEdit.id;
          updateInformation(data)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_edit"),
                  color: "success"
                });
                this.closeModalHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        } else {
          addInformation(data)
            .then(res => {
              if (res.data.code) {
                const data = res.data.data;
                if (data.type_for === "kelas_tertentu") {
                  this.$socket.emit("serverNotif", {
                    recipient: data.recipient,
                    data: data
                  });
                } else {
                  this.$socket.emit("serverNotif", {
                    recipient: data.recipient,
                    data: data
                  });
                }

                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.closeModalHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        }
      }
    }
  }
};
</script>
